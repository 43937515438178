import { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import parse from 'html-react-parser';
import axios from '../../services/backendService';

//import blankImg from '../../resources/img/blank1.svg';
import Background from '../../components/background/background';
import Loader from '../../components/loader/Loader';
import SidebarLink from '../../components/sidebarLink/SidebarLink';
import Error from '../../components/error/Error';


import './nestedPage.scss';
import PageNotFound from '../../components/pageNotFound/PageNotFound';

const NestedPage = () => {

     const location = useLocation();
     const [error, setError] = useState(null);
     const [notFound, setNotFound] = useState(null);
     const [link, setLink] = useState(location.pathname);
     const [isLoading, setIsLoading] = useState(true);
     const [generalInformation, setGeneralInformation] = useState({});
     const [data, setData] = useState(null);
     const [lang, setLang] = useState(localStorage.getItem('lang'));

     console.log(axios.defaults.baseURL);

     useEffect(() => {
          setLink(location.pathname);
     }, [location.pathname]);
     useEffect(() => {
          setIsLoading(true);
          const fetchData = async () => {
               try {
                    const responseGeneralInformation = await axios.get('/general');
                    const responsePage = await axios.get(`${axios.defaults.baseURL}${link}`);
                    setGeneralInformation(responseGeneralInformation.data);
                    setData(responsePage.data);
               } catch (error) {
                    if (error.response.status === 404) {
                         setNotFound(error)
                    } else {
                         setError(error);
                    }
               }
               setIsLoading(false);
          };
          fetchData();
          
     }, [link]);

     useEffect(() => {
          window.scrollTo(0, 0);
          const observer = new MutationObserver((mutations) => {
               mutations.forEach((mutation) => {
                    if (mutation.attributeName === 'lang') {
                         localStorage.setItem('lang', mutation.target.getAttribute('lang'));
                         setLang(localStorage.getItem('lang'));
                    }
               });
          });
      
          observer.observe(document.documentElement, { attributes: true, attributeFilter: ['lang'] });
          return () => {
               observer.disconnect();
          };
     }, []);

     useEffect(() => {
          if (data) {
               document.title = `${
                    lang === 'ru' ? data.data.title.ru :
                    lang === 'kz' ? data.data.title.kz :
                    data.data.title.en
               } - HAO Turar Healthcare`;
          }
     }, [isLoading, lang]);


     if (isLoading) {
          return (
               <Loader/>
          )
     }

     if (notFound) {
          return (
               <PageNotFound
                    title={
                         lang === 'ru' ? 'Похоже, что страница не существует или была перемещена.' :
                         lang === 'kz' ? 'Белгілі бет жоқ немесе тасымалданды.' :
                         'It looks like the page doesn`t exist or has been moved.'
                    }/>
          )
     }

     if (error) {
          return (
               <Error/>
          )
     }
     console.log(data);
     return (
          <>
               <Background
                    bgImage={generalInformation.bgImage}
                    link={`/${data.data.link}`}
                    nestedRuTitle={data.data.title.ru}
                    nestedKzTitle={data.data.title.kz}
                    nestedEnTitle={data.data.title.en}
                    parentRuTitle={data.parent.title.ru}
                    parentKzTitle={data.parent.title.kz}
                    parentEnTitle={data.parent.title.en}/>
               <div className="container">
                    <div className="nested__wrapper">
                         <div className="nested__part">
                              {
                                   data.parent.nestedPages
                                   .filter(it => {
                                        return it._id !== '641c9dd0812f801bbb67cec3' && it._id !== '641c9e22812f801bbb67d0f6'
                                   })
                                   .map((obj) => (
                                        <SidebarLink
                                             key={obj._id}
                                             link={obj.link}
                                             ruName={obj.title.ru}
                                             kzName={obj.title.kz}
                                             enName={obj.title.en}
                                             classNames={
                                                  link.substring(1) === obj.link ? "page__sidebar-link page__sidebar-link-active"
                                                  : "page__sidebar-link"
                                             }/>
                                   ))
                              }
                              {
                                   data.parent.link === 'about' && (
                                        <SidebarLink
                                             key={888}
                                             link="career"
                                             ruName="Карьера"
                                             kzName="Карьера"
                                             enName="Career"
                                             classNames={
                                                  link.substring(1) === data.parent.link ? "page__sidebar-link page__sidebar-link-active"
                                                  : "page__sidebar-link"
                                             }/>
                                   )
                              }
                         </div>
                         <div className="nested__part">
                              <div className="page">
                                   {/* <div className="page__title">
                                        <div className="section__title">
                                             <img src={blankImg} className="section__title-img" alt=""/>
                                             <h3 className="section__title-txt">{
                                                  lang === 'ru' ? data.data.title.ru : 
                                                  lang === 'kz' ? data.data.title.kz : 
                                                  data.data.title.en
                                             }</h3>
                                             <div className="section__title-border"></div>
                                        </div>
                                   </div> */}
                                   <div className="page__content">
                                        {
                                             data.data.isListOfDocuments ? (
                                                  <ol className="documents__list">{
                                                       data.data.documents.map(doc => (
                                                            <li className="documents__item">
                                                                 <Link
                                                                      to={`${axios.defaults.baseURL}/uploads/documents/${doc.filename}`}
                                                                      rel="noopener noreferrer"
                                                                      target="_blank"
                                                                      >
                                                                      {
                                                                           lang === 'ru' ? doc.name.ru :
                                                                           lang === 'kz' ? doc.name.kz :
                                                                           doc.name.en
                                                                      }
                                                                 </Link>
                                                            </li>
                                                       ))
                                                  }</ol>
                                                  
                                             ) : (
                                                  parse(
                                                       lang === 'ru' ? data.data.content.ru : 
                                                       lang === 'kz' ? data.data.content.kz : 
                                                       data.data.content.en
                                                  )
                                             )
                                        }
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     )
}

export default NestedPage;