import { useState, useEffect } from "react";
import axios from '../../services/backendService';

import Background from "../../components/background/background";
import Loader from "../../components/loader/Loader";
import NotFound from "../../components/notFound/NotFound";

import './mailPage.scss';
import ChairmanBlogItem from "../../components/chairmanBlogItem/ChairmanBlogItem";

const MailPage = () => {
     const [isLoading, setIsLoading] = useState(true);
     const [generalInformation, setGeneralInformation] = useState({});
     const [mails, setMails] = useState([]);
     const [isInfo, setIsInfo] = useState(true);
     const [lang, setLang] = useState(localStorage.getItem('lang'));
     const [name, setName] = useState('');
     const [surname, setSurname] = useState('');
     const [email, setEmail] = useState('');
     const [message, setMessage] = useState('');
     const [selectedFiles, setSelectedFiles] = useState([]);
     const [sendMessage, setSendMessage] = useState('');
     const [sendStatus, setSendStatus] = useState('');
     const handleChangeName = (event) => {
          setName(event.target.value);
     }
     const handleChangeSurname = (event) => {
          setSurname(event.target.value);
     }
     const handleChangeEmail = (event) => {
          setEmail(event.target.value);
     }
     const handleChangeMessage = (event) => {
          setMessage(event.target.value);
     }
     const handleChangeFiles = (event) => {
          const newFiles = [...selectedFiles];
          for (let i = 0; i < event.target.files.length; i++) {
               newFiles.push(event.target.files[i]);
          }
          setSelectedFiles(newFiles);
          console.log(selectedFiles);
     };

     const handleChangeToogleInfo = (event) => {
          if (event.target.id === "blog") {
               setIsInfo(false);
          } else {
               setIsInfo(true);
          }
     }
     
     useEffect(() => {
          const fetchData = async () => {
               try {
                    const responseGeneralInformation = await axios.get('/general');
                    const responseMails = await axios.get('/mail/access');
                    setGeneralInformation(responseGeneralInformation.data);
                    setMails(responseMails.data);
                    setIsLoading(false);
               } catch (error) {
                    console.log(error);
               }
          }
          fetchData();

          window.scrollTo(0, 0);

          document.title = `${
               lang === 'ru' ? 'Блог председателя' :
               lang === 'kz' ? 'Төраға блогы' :
               'Chairman`s blog'
          } - HAO Turar Healthcare`;

          const observer = new MutationObserver((mutations) => {
               mutations.forEach((mutation) => {
                    if (mutation.attributeName === 'lang') {
                         localStorage.setItem('lang', mutation.target.getAttribute('lang'));
                         setLang(localStorage.getItem('lang'));
                    }
               });
          });
      
          observer.observe(document.documentElement, { attributes: true, attributeFilter: ['lang'] });
          return () => {
               observer.disconnect();
          };
     }, []);


     const sendMail = async (event) => {
          event.preventDefault();
          try {
               setSendMessage(
                    lang === 'ru' ? 'Идет отправка сообщения...' :
                    lang === 'kz' ? 'Хабарлама жіберіп жатыр...' :
                    'The message is being sent...'
               )
               setSendStatus('loading');
               const formData = new FormData();
               formData.append('name', name);
               formData.append('surname', surname);
               formData.append('email', email);
               formData.append('message', message);
               if (selectedFiles.length !== 0) {
                    console.log(selectedFiles.length);
                    Array.from(selectedFiles).forEach(file => {
                         formData.append('files', file);
                    });
               }
               await axios.post('/mail', formData);
               setSendMessage(
                    lang === 'ru' ? 'Ваше сообщение отправлено!\nСпасибо за обращение!' :
                    lang === 'kz' ? 'Сіздің хабарламаңыз жіберілді!\nӨтінім бергеніңіз үшін рахмет!' :
                    'Your message has been sent!\nThank you for contacting us!'
               )
               setSendStatus('success');
          } catch (error) {
               console.log(error);
               setSendMessage(
                    lang === 'ru' ? 'Произошла ошибка при отправке сообщения.\nПрошу повторите позже!' :
                    lang === 'kz' ? 'Хабарлама жіберу кезінде қате пайда болды.\nКейінірек қайталауды сұраймын!' :
                    'An error occurred while sending the message.\nPlease repeat later!'
               )
               setSendStatus('error');
          }
     } 

     if (isLoading) {
          return (
               <Loader/>
          )
     }

     return (
          <>
               <Background
                    bgImage={generalInformation.bgImage}
                    link={null}
                    nestedRuTitle={null}
                    nestedKzTitle={null}
                    nestedEnTitle={null}
                    parentRuTitle="блог председателя"
                    parentKzTitle="объекттер картасы"
                    parentEnTitle="map of objects"/>
               <div className="container">
                    <div className="mail__wrapper">
                         <div className="mail__btn-list">
                              <div className={isInfo ? "mail__btn-item mail__btn-item-active" : "mail__btn-item"}
                                   id="form"
                                   onClick={handleChangeToogleInfo}>
                                   {
                                        lang === 'ru' ? 'Задать вопрос' : 
                                        lang === 'kz' ? 'Сұрақ қою' : 
                                        'Ask a question'
                                   }
                              </div>
                              <div className={isInfo ? "mail__btn-item" : "mail__btn-item mail__btn-item-active"}
                                   id="blog"
                                   onClick={handleChangeToogleInfo}>
                                   {
                                        lang === 'ru' ? 'Все записи' : 
                                        lang === 'kz' ? 'Барлық жазбалар' : 
                                        'All posts'
                                   }
                              </div>
                         </div>
                         <div className="mail__request"
                              style={isInfo ? {'display':'flex'} : {'display':'none'}}>

                                   <form className="mail__form" onSubmit={sendMail}>
                                        <h1>{
                                             lang === 'ru' ? 'Оставить заявку' :
                                             lang === 'kz' ? 'Өтініш қалдыру' :
                                             'Submit an application'
                                        }</h1>
                                        <h6 className="mail__title">{
                                             lang === 'ru' ? 'Ваше имя' :
                                             lang === 'kz' ? 'Сіздің атыңыз' :
                                             'Your first name'
                                        } *</h6>
                                        <input type="text" className="mail__input" value={name} onChange={handleChangeName}/>
                                        <h6 className="mail__title">{
                                             lang === 'ru' ? 'Ваша фамилия' :
                                             lang === 'kz' ? 'Сіздің тегініз' :
                                             'Your last name'
                                        } *</h6>
                                        <input type="text" className="mail__input" value={surname} onChange={handleChangeSurname}/>
                                        <h6 className="mail__title">E-mail *</h6>
                                        <input type="email" className="mail__input" value={email} onChange={handleChangeEmail}/>
                                        <h6 className="mail__title">{
                                             lang === 'ru' ? 'Ваша заявка' :
                                             lang === 'kz' ? 'Сіздің өтінішіңіз' :
                                             'Your request'
                                        } *</h6>
                                        <textarea className="mail__input mail__textarea" value={message} onChange={handleChangeMessage}></textarea>
                                        <div className="mail__upload">
                                             <label htmlFor="files" className="mail__files">{
                                                  lang === 'ru' ? 'Прикрепить файлы' :
                                                  lang === 'kz' ? 'Файлдарды тіркеу' :
                                                  'Attach files'
                                             }</label>
                                             <p className="mail__upload-message">{
                                                  selectedFiles.map(file => (
                                                       `${file.name}\n`
                                                  ))
                                             }</p>
                                        </div>
                                        <input type="file" id="files" multiple onChange={handleChangeFiles} />
                                        <input 
                                             type="submit"
                                             className="mail__submit"
                                             value={
                                                  lang === 'ru' ? 'оставить заявку' :
                                                  lang === 'kz' ? 'өтініш қалдыру' :
                                                  'Submit an application'
                                             } />
                                        <div className="mail__result">
                                             <div className={
                                                  sendStatus === 'success' ? "mail__result-border mail__result-border-green" : 
                                                  sendStatus === 'error' ? "mail__result-border mail__result-border-red" : 
                                                  sendStatus === 'loading' ? "mail__result-border mail__result-border-yellow" : 
                                                  "mail__result-border" 
                                             }></div>
                                             <p className="mail__result-text">{sendMessage}</p>
                                        </div>
                                   </form>

                              </div>
                         <div className="mail__blog"
                              style={isInfo ? {'display':'none'} : {'display':'block'}}>{
                                   mails.map(mail => (
                                        <ChairmanBlogItem obj={mail}/>
                                   ))
                              }
                              {
                                   mails.length === 0 && (
                                        <NotFound
                                             title={
                                                  lang === 'ru' ? 'Не удалось найти записи по данному запросу' :
                                                  lang === 'kz' ? 'Осы сұраныс бойынша жазбаларды табылмады' :
                                                  'Couldn`t find records for this query'
                                             }/>
                                   )
                              }</div>
                    </div>
               </div>
          </>
          
     )
}

export default MailPage;