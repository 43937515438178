import { Routes, Route } from 'react-router-dom';

import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import HomePage from './pages/homePage/HomePage';
import ArticlePage from './pages/articlePage/ArticlePage';
import NestedPage from './pages/nestedPage/NestedPage';
import BlogPage from './pages/blogPage/BlogPage';
import CityPage from './pages/cityPage/CityPage';
import ContactsPage from './pages/contactsPage/ContactsPage';
import CareerPage from './pages/careerPage/CareerPage';
import MailPage from './pages/mailPage/MailPage';

import './App.scss';

function App() {
     return (
          <>
               <Header/>
               <Routes>
                    <Route exact path='/' element={
                         <HomePage/>
                    }/>
                    <Route path='/press-center/:id' element={
                         <ArticlePage/>
                    }/>
                    <Route path='/press-center' element={
                         <BlogPage/>
                    }/>
                    <Route path="*" element={
                         <NestedPage/>
                    }/>
                    <Route path="/city/:city" element={
                         <CityPage/>
                    }/>
                    <Route path="/contacts" element={
                         <ContactsPage/>
                    }/>
                    <Route path='/career' element={
                         <CareerPage/>
                    }/>
                    <Route path='/blog' element={
                         <MailPage/>
                    }/>
               </Routes>
               <Footer/>
          </>

     );
}

export default App;
