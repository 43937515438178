import { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import axios from '../../services/backendService';

import Error from "../../components/error/Error";
import Loader from "../../components/loader/Loader";
import Background from "../../components/background/background";

import './contactsPage.scss';

const ContactsPage = () => {
     const [lang, setLang] = useState(localStorage.getItem('lang'));
     const [generalInformation, setGeneralInformation] = useState({});
     const [isLoading, setIsLoading] = useState(true);
     const [error, setError] = useState(null);
     useEffect(() => {
          const fetchData = async () => {
               try {
                    const responseGeneralInformation = await axios.get('/general');
                    setGeneralInformation(responseGeneralInformation.data);
                    setIsLoading(false);
               } catch (err) {
                    setError(err);
               }
          }
          fetchData();

          window.scrollTo(0, 0);

          const observer = new MutationObserver((mutations) => {
               mutations.forEach((mutation) => {
                    if (mutation.attributeName === 'lang') {
                         localStorage.setItem('lang', mutation.target.getAttribute('lang'));
                         setLang(localStorage.getItem('lang'));
                    }
               });
          });
      
          observer.observe(document.documentElement, { attributes: true, attributeFilter: ['lang'] });
          return () => {
               observer.disconnect();
          };
     }, []);

     useEffect(() => {
          document.title = `${lang === 'ru' ? 'Контакты' : lang === 'kz' ? 'Байланыс' : 'Contacts'} - HAO Turar Healthcare`;
     }, [lang]);

     if (isLoading) {
          return (
               <Loader/>
          )
     }

     if (error) {
          return (
               <Error/>
          )
     }

     return (
          <>
               <Background
                    bgImage={generalInformation.bgImage}
                    link={null}
                    nestedRuTitle={null}
                    nestedKzTitle={null}
                    nestedEnTitle={null}
                    parentRuTitle="контакты"
                    parentKzTitle="байланыс"
                    parentEnTitle="contacts"/>
               <section className="contacts">
                    <div className="container">
                         <div className="contacts__wrapper">
                              <div className="contacts__part">
                                   <div className="contacts__info">
                                        <div className="contacts__title">{
                                             lang === 'ru' ? 'Связаться с нами' :
                                             lang === 'kz' ? 'Бізбен байланыс' :
                                             'Contact us'
                                        }</div>
                                        <div className="contacts__info-item">
                                             <i className="fa-solid fa-location-dot"></i>
                                             {
                                                  lang === 'ru' ? generalInformation.address.ru : 
                                                  lang === 'kz' ? generalInformation.address.kz : 
                                                  generalInformation.address.en
                                             }
                                        </div>
                                        <div className="contacts__info-item">
                                             <i className="fa-solid fa-phone"></i>
                                             <Link to="#">{generalInformation.phoneNumber}</Link>
                                        </div>
                                        <div className="contacts__info-item">
                                             <i className="fa-solid fa-envelope"></i>
                                             <Link to={"mailto:" + generalInformation.mail}>{generalInformation.mail}</Link>
                                        </div>
                                        <div className="contacts__info-item">
                                             <i class="fa-solid fa-address-book"></i>
                                             <Link 
                                                  to={`${axios.defaults.baseURL}/uploads/contacts/${generalInformation.listOfNumbers}`}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="contacts__info-link">{
                                                       lang === 'ru' ? 'Телефонный справочник' :
                                                       lang === 'kz' ? 'Телефон анықтамалығы' :
                                                       'Telephone directory'
                                                  }</Link>
                                        </div>
                                   </div>
                              </div>
                              <div className="contacts__part">
                                   <iframe 
                                        title="Яндекс Карта"
                                        src="https://yandex.ru/map-widget/v1/?um=constructor%3Ab533f46cf42af9b812f01bb9b8cdc5a3c673a61639594103600b3eefb7073d3c&amp;source=constructor"
                                        frameBorder="0">
                                   </iframe>
                              </div>
                              
                         </div>
                    </div>
               </section>
          </>
     )
}

export default ContactsPage;