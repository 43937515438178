import { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../services/backendService';

import './chairmanBlogItem.scss';

const ChairmanBlogItem = ({obj}) => {
     const lang = localStorage.getItem('lang');
     const [isMore, setIsMore] = useState(false);
     const [answerIsShow, setAnswerIsShow] = useState(false);
     const handleChangeMore = () => {
          setIsMore(!isMore);
     }
     const handleChangeAnswer = () => {
          setAnswerIsShow(!answerIsShow);
     }

     const showCorrectDateFormat = (dateStr) => {
          const date = new Date(dateStr);
          const day = date.getDate();
          const month = date.getMonth();
          const year = date.getFullYear();
          const hour = date.getHours();
          const minutes = date.getMinutes();
          return `${getZero(day)}.${getZero(month)}.${getZero(year)} ${getZero(hour)}:${getZero(minutes)}`;
     }
     const getZero = (num) => (num > 9 ? ""+ num : "0"+num);

     const showShortContent = (txt) => {
          const arrayOfText = txt.split(' ');
          let num = 0;
          let text = '';
          for (let i = 0; i < arrayOfText.length; i++) {
               num += arrayOfText[i].length;
               if (num < 100) {
                    text += `${arrayOfText[i]} `;
               }
          }
          if (num >= 100) {
               text = text.substring(0, text.length - 1);
               text += '...';
          }
          return text;
     }

     return (
          <div className="chairman__item">
               <div className="chairman__item-header">
                    <div className="chairman__item-header-part">
                         <div className="chairman__item-header-item">
                              <i class="fa-solid fa-user"></i>
                              <p>{obj.senderData.name} {obj.senderData.surname}</p>
                         </div>
                         <div className="chairman__item-header-item">
                              <i class="fa-solid fa-at"></i>
                              <p>{obj.senderData.email}</p>
                         </div>
                         <div className="chairman__item-header-item">
                              <i class="fa-regular fa-calendar"></i>
                              <p>{showCorrectDateFormat(obj.createdAt)}</p>
                         </div>
                    </div>
               </div>    
               <h2 className="chairman__item-title">Сообщение: </h2>           
               <p className="chairman__item-message">{
                    isMore ? obj.message : showShortContent(obj.message)  
               }</p>
               <div>
               {
                    obj.message.length > 100 && (
                         <span className="chairman__item-more" onClick={handleChangeMore}>{
                              lang === 'ru' ? 'Подробнее' :
                              lang === 'kz' ? 'Толығырақ' :
                              'More'
                         }</span>
                    )
               }
                    <span className="chairman__item-more" onClick={handleChangeAnswer}>{
                         lang === 'ru' ? 'Показать ответ' :
                         lang === 'kz' ? 'Жауапты көрсету' :
                         'Show answer'
                    }</span>
               </div>
               {
                    obj.files.length > 0 && (
                         <>
                              <h2 className="chairman__item-title">Прикрепленные файлы:</h2>           
                              <ol>
                              {
                                   obj.files.map(file => (
                                        <li className="chairman__item-file">
                                             <Link
                                                  to={`${axios.defaults.baseURL}/uploads/mail/${file}`}>
                                                  {file}
                                             </Link>
                                        </li>
                                   ))
                              }
                              </ol>
                         </>
                    )
               }
               {
                    answerIsShow && (
                         <>
                              <h2 className="chairman__item-title">Ваш ответ: </h2>           
                              <p className="chairman__item-message">{obj.answer}</p>
                         </>
                    )
               }
          </div>

     )
}

export default ChairmanBlogItem;