import { Link } from 'react-router-dom';

import './sidebarLink.scss';

const SidebarLink = ({link, ruName, kzName, enName, classNames, handleFunction, id}) => {
     const lang = localStorage.getItem('lang');
     return (
          link ? (
               <Link to={link} className={classNames}>
                    <div className="page__sidebar-link-border"></div>
                    {
                         lang === 'ru' ? ruName :
                         lang === 'kz' ? kzName :
                         enName
                    }
               </Link>
          ) : (
               <div className={classNames} onClick={handleFunction} id={id}>
                    <div className="page__sidebar-link-border"></div>
                    {
                         lang === 'ru' ? ruName :
                         lang === 'kz' ? kzName :
                         enName
                    }
               </div>   
          )
          
     );
}

export default SidebarLink;